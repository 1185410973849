import React from "react";
import SEO from "./seo";
import Hero from "./hero";
import Header from "./header";
import BackgroundImage from 'gatsby-background-image'

const AboveFold = ({title, altTitle, subtitle, description, canonical, img, ctaText, ctaLink, lmText, lmLink, children}) => {

  return (        
  
    <BackgroundImage
          Tag="section"
          fluid={img}
          className="cg-aboveFold"
          backgroundColor={`#333`}
          style={{backgroundPosition:'left center'}}
        >
      <SEO 
        title={title} 
        description={description} 
        canonical={canonical}
      />
      <Header ctaText={ctaText} ctaLink={ctaLink} lmText={lmText} lmLink={lmLink}/>
      <div style={{textAlign:'center', padding:'0.7rem 1.5rem'}}>
        <Hero 
          title={altTitle || title}  
          subtitle={subtitle} 
          ctaText={ctaText} 
          ctaLink={ctaLink}
          lmText={lmText} 
          lmLink={lmLink}
          >
          {children}
        </Hero>
      </div>
    </BackgroundImage>
  )
}

export default AboveFold
