import React from "react";
// import Slider from "react-slick";
import {Link} from "gatsby";

class Hero extends React.Component {
  //constructor(props) {
  //    super(props);
  //}

  render() {
        // For react-slick docs, see https://react-slick.neostack.com/
    // const sliderSettings = {
    //   dots: false,
    //   infinite: true,
    //   speed: 3500,
    //   slidesToShow: 1, 
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   adaptiveHeight: false,
    //   arrows: false,
    //   centerMode: false,
    //   draggable: false,
    //   // responsive: [
    //   //   {
    //   //     breakpoint: 350,
    //   //     settings: {
    //   //       autoplay: false,
    //   //     }
    //   //   },
    //   // ],
    // };
    
    return (
      <div>
        <div className='contentBox jumbotron' >
          {/*
          
          {(this.props.titles)?(
            <Slider {...sliderSettings}>
              {this.props.titles.map((title) =>
                  <h1 className='display-4' key={title} style={{ height:'2.5rem' }}>{title}</h1>
              )}
            </Slider>
            ) : (
              <h1 className='display-4'>{this.props.title}</h1>
            )
          }
            
          <br />
          */}
          <div>
            {this.props.children}
          </div>
          {this.props.subtitle && 
            <h2>{this.props.subtitle}</h2>
          }
          
          <a className="cg-secondary btn btn-secondary" href={this.props.lmLink} >{this.props.lmText}</a>
          <Link className="cg-dark btn btn-primary" to={this.props.ctaLink} >{this.props.ctaText}</Link>

        </div>
    </div>
    );
  }
}

export default Hero;